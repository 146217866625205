import React from 'react';
import './Navbar.css';
import logo from '../assets/logo.png';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className="navbar">
      <NavLink to="/">
        <img src={logo} alt="Logo" className="logo" />
      </NavLink>
      <div className="navbar-links">
        <NavLink to="/" activeClassName="active">
          Home
        </NavLink>

        <NavLink to="/ContactUs" activeClassName="active">
          Contact Us
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
