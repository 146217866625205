import React from 'react';
import HomeWelcome from './HomeWelcome';
import HomeServices from './HomeServices';
import HomeQuote from './HomeQuote';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="home-container">
      <HomeWelcome />
      <HomeServices />
      <HomeQuote />
      <Footer />
    </div>

  );
};

export default Home;
