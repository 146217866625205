import React from 'react';
import audi from '../assets/audi.png';
import solargard from '../assets/solargard.png';
import '../styles/HomeWelcome.css';

const HomeWelcome = () => {
  return (
    <div className="audi-container">
        <img src={audi} alt="Audi Logo" className="audi" />
        <img src={solargard} alt="Solargard Logo" className="solargard" />
      </div>
  );
};

export default HomeWelcome;
