import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomeQuote.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const HomeQuotes = () => {
  return (
    <div className="free-quote-container">
      <h3>GET A FREE QUOTE TODAY!</h3>
      <p>Curious about the cost of our top-notch mobile tinting services for your vehicle? We understand the importance of transparency, and that's why we offer free quotes tailored to your needs.</p>

      <div className="contact-info">
        <ul>
          <li className="blue-text"><FontAwesomeIcon icon={faPhone} size="xl" style={{ color: "#0050ef", marginBottom: '20px' }} /></li>
          <li className="blue-text"><FontAwesomeIcon icon={faEnvelope} size="xl" style={{ color: "#0050ef", marginBottom: '20px' }} /></li>
          <li className="blue-text"><FontAwesomeIcon icon={faLocationDot} size="xl" style={{ color: "#0050ef", marginBottom: '20px', paddingLeft: '3px' }} /></li>
        </ul>

        <ul>
          <li className="black-text">0451 694 387</li>
          <li className="black-text">natestint@hotmail.com</li>
          <li className="black-text">60 Alinga Street, Cabramatta West, NSW 2166</li>
        </ul>
      </div>

      <div className="enquire-button-container">
        <Link to="/ContactUs" className="enquire-button">
          ENQUIRE NOW!
        </Link>
      </div>
    </div>
  );
};

export default HomeQuotes;
