import React from 'react';
import '../styles/ContactUsTitle.css';
import Footer from '../components/Footer';

const ContactUs = () => {
  return (
    <div className="contactus">
      <div className="map-section">
        <div className="gmap-frame">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.7295733015153!2d150.9174957108046!3d-33.89661787310425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1297da3d4ad48f%3A0x37b3c6cbf770048a!2sNate&#39;s%20Tint!5e0!3m2!1sen!2sau!4v1711194293525!5m2!1sen!2sau" width="100%" height="500" frameBorder="0" loading="eager" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

    <div className="contact-container">
    <div className="contact-left-container">
      <h3 className="contact-subtitle">CONTACT DETAILS</h3>

      <div className="contact-detail">
      <div className="contact-phone-email">
        <p>Phone</p>
        <p>Email</p>
      </div>

      <div className="contact-phone-email-info">
      <div>
        <p>0451 694 387</p>
        <p>natestint@hotmail.com</p>
      </div>
      </div>

      </div>

      <h3 className="contact-subtitle">LOCATION</h3>

      <div className="location">
      <div className="location-address">
        <p>Address</p>
      </div>

      <div className="location-address-details">
      <div>
        <p>60 Alinga Street, Cabramatta West, NSW 2166</p>
        <p>Sydney, Australia</p>
      </div>
      </div>
    </div>

    </div>

    <div className="contact-right-container">

      <h3 className="contact-subtitle">BUSINESS HOURS</h3>

      <div className="contact-business-hours">
      <div className="contact-business-day">
        <p>Monday</p>
        <p>Tuesday</p>
        <p>Wednesday</p>
        <p>Thursday</p>
        <p>Friday</p>
        <p>Saturday</p>
        <p>Sunday</p>
      </div>

      <div className="contact-business-time">
      <div>
        <p>8:00am - 4:00pm</p>
        <p>8:00am - 4:00pm</p>
        <p>8:00am - 4:00pm</p>
        <p>8:00am - 4:00pm</p>
        <p>8:00am - 4:00pm</p>
        <p>Closed</p>
        <p>Closed</p>
      </div>
      </div>
       
    </div>

    </div> 

    </div>

    <Footer />
    </div>

  );
};

export default ContactUs;
