import React from 'react';
import service1Image from '../assets/ourservices1.png'; 
import service2Image from '../assets/ourservices2.png';
import service3Image from '../assets/ourservices3.png';
import '../styles/HomeServices.css';

const HomeServices = () => {
  return (
    <div className="our-services-section">
      <div className="our-services-container">
        <h3>OUR SERVICES</h3>
      </div>
      <div className="services-container">
        <div className="service-container">
          <img src={service1Image} alt="Service 1"/>
          <div className="service">
            <h4 className="our-services-subtitle">AUTOMOTIVE WINDOW TINTING</h4>
            <p className="our-services-description">Enhance the look and comfort of your vehicle with our professional tinting services. Enjoy increased privacy, reduced glare, and protection from harmful UV rays.</p>
          </div>
        </div>
        <div className="service-container">
          <img src={service2Image} alt="Service 2"/>
          <div className="service">
            <h4 className="our-services-subtitle">INTERIOR / EXTERIOR CLEANING</h4>
            <p className="our-services-description">Treat your car to a refreshing experience! Our cleaning services bring back the shine to both the inside and outside, making your vehicle look and feel revitalised, just like new.</p>
          </div>
        </div>
        <div className="service-container">
          <img src={service3Image} alt="Service 3"/>
          <div className="service">
            <h4 className="our-services-subtitle">PAINT PROTECTION FILM (PPF)</h4>
            <p className="our-services-description">Shield your vehicle from road debris, stone chips, and harsh weather with our high-quality Paint Protection Film. Preserve the beauty of your car's finish for years to come.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
