import React from 'react';
import './Footer.css';
import logowords from '../assets/logowords.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-logo">
         <Link to="/ContactUs" className="logowords-button">
         <img src={logowords} alt="Logo" className="logowords" />
        </Link>
      </div>

      <div className="footer-contact-details">
        <p className="footer-title">CONTACT DETAILS</p>
        <ul>
          <li className="footer-text">0451 694 387</li>
          <li className="footer-text">natestint@hotmail.com</li>
          <li className="footer-text">60 Alinga Street, Cabramatta West, NSW 2166</li>
        </ul>
      </div>

      <div className="footer-business-hours">
      <p className="footer-title">BUSINESS HOURS</p>
        <ul>
          <li className="footer-day">Monday - Friday: 8:00 AM to 4:00 PM</li>
          <li className="footer-day">Saturday: Closed</li>
          <li className="footer-day">Sunday: Closed</li>
        </ul>
      </div>

      <div className="social-media-icons">
        <a href="https://www.instagram.com/natestint" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: "#1d57cc", marginRight: '20px' }} />
        </a>
        <a href="https://www.facebook.com/natestint" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSquareFacebook} size="2x" style={{ color: "#1d57cc" }} />
        </a>
      </div>

      <footer className="footer-copyright">
        <p>&copy; 2024 Nate's Tint. All rights reserved.</p>
      </footer>

    </div>
  );
};

export default Footer;
